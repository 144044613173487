.footer {
    padding-bottom: 30px;

    &.theme__septenary {
        background-color: $color-septenary-200;
    }

    .container {
        padding: 0;
    }
}

.footer__container {
    max-width: 100%;
}

.footer__img {
    padding: 20px 0 10px;
}

.footer__copy {
    display: flex;
    flex-direction: column;

    padding: 20px 0 0;

    font-size: 16px;
    line-height: 28px;

    .font-primary {
        padding-left: 20px;
    }
}

.copy__links {
    color: #d6c0a2;

    text-decoration: none;
}

.footer__facebook {
    margin-top: 25px;
    margin-left: -2px;
    padding-left: 20px;

    color: #d6c0a2;

    font-size: 40px;

    text-decoration: none;
}

.footer__links {
    margin-top: 10px;

    padding-right: 0;
    padding-left: 0;

    .accordion:first-of-type {
        margin-top: 0;
    }
}

@include media-breakpoint-up(md) {
    .footer {
        display: flex;

        .container {
            padding: 0 30px;
        }
    }

    .footer__container {
        max-width: map-get($container-max-widths, md);
    }

    .footer__img {
        padding: 30px 0 25px;
    }

    .footer__copy {
        padding: 20px 0 0;

        text-align: left;

        .font-primary {
            padding-left: 0;
        }
    }

    .footer__facebook {
        margin-top: 15px;

        padding-left: 0;
    }

    .copy__tel {
        color: $color-white;

        pointer-events: none;
    }
}

@include media-breakpoint-up(lg) {
    .footer__container {
        max-width: map-get($container-max-widths, lg);
    }
}

@include media-breakpoint-up(xl) {
    .footer {
        .accordion__headline {
            display: block;
            padding: 0;

            pointer-events: none;
        }

        .accordion__content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding-top: 5px;
            padding-bottom: 20px;
        }

        .link-list__item {
            border-bottom: 0;

            background: transparent;

            a {
                display: inline-block;
                padding: 0;

                color: $color-tertiary;
                font-size: 16px;

                &:hover {
                    color: $color-white;
                }
            }

            &--active {
                a:hover {
                    color: $color-white;

                    font-weight: $font-weight-normal;
                }
            }
        }
    }

    .footer__container {
        max-width: map-get($container-max-widths, xl);

        > .no-gutters {
            margin-right: -15px;
            margin-left: -15px;
        }

        .footer__links,
        .footer__company {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .footer__img {
        padding: 45px 0 0;
    }

    .footer__copy {
        padding: 40px 0 50px;
    }

    .footer__links {
        margin-top: 35px;
        padding-top: 10px;
    }

    .copy__links:hover {
        color: $color-white;

        text-decoration: none;
    }

    .footer__facebook {
        margin-top: 20px;

        font-size: 20px;
    }

    .footer__facebook:hover {
        color: $color-white;

        text-decoration: none;
    }
}
