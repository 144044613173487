.theme__elements--dark {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        color: $color-black;
        font-family: $font-primary-stack;
    }

    p {
        color: $color-secondary-100;
    }

    .copy {
        color: $color-secondary-100;
    }

    .btn {
        border: 2px solid $color-secondary-100;

        color: $color-secondary-100;

        &:hover {
            color: $color-white;

            background-color: $color-secondary-100;
        }
    }

    .subline--tertiary {
        color: darken($color-tertiary-150, 10);
    }

    .headline {
        color: $color-secondary-100;
    }

    .teaser__headline,
    .teaser__subline {
        color: $color-secondary-100;
    }

    .splide__arrow {
        border-color: $color-secondary-100;

        fill: $color-secondary-100;
    }
}

.theme__elements--tertiary {
    a,
    .link-icon__link {
        color: $color-tertiary;
        text-decoration: none;

        &:hover {
            color: $color-white;
        }
    }
}
