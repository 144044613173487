@font-face {
    font-weight: normal;
    font-family: "twocream-iconfont";
    font-style: normal;
    src: url("#{$font-path}twocream-icons/twocream-iconfont.eot?tp8zld");
    src: url("#{$font-path}twocream-icons/twocream-iconfont.eot?tp8zld#iefix") format("embedded-opentype"),
        url("#{$font-path}twocream-icons/twocream-iconfont.ttf?tp8zld") format("truetype"),
        url("#{$font-path}twocream-icons/twocream-iconfont.woff?tp8zld") format("woff"),
        url("#{$font-path}twocream-icons/twocream-iconfont.svg?tp8zld#twocream-iconfont") format("svg");
    font-display: block;
}

%icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-weight: normal;
    font-family: "twocream-iconfont" !important; /* stylelint-disable-line */
    speak: none;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-transform: none;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
    @extend %icon;
}

.icon-arrow-up::before {
    content: "\e900";
}

.icon-arrow-right::before {
    content: "\e901";
}

.icon-arrow-down::before {
    content: "\e902";
}

.icon-arrow-left::before {
    content: "\e903";
}

.icon-arrow-menue-up::before {
    content: "\e904";
}

.icon-arrow-menue-right::before {
    content: $icon-arrow-menu-right;
}

.icon-arrow-menue-down::before {
    content: $icon-arrow-menu-down;
}

.icon-arrow-menue-left::before {
    content: "\e907";
}

.icon-plus::before {
    content: $icon-plus;
}

.icon-minus::before {
    content: $icon-minus;
}

.icon-menue::before {
    content: "\e90a";
}

.icon-badge::before {
    content: "\e90c";
}

.icon-sm-facebook::before {
    content: "\e90d";
}
