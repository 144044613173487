.image {
    transition: transform $animation-speed ease;

    &--full-width {
        width: 100%;
        height: auto;
    }
}

.image--blur-up {
    filter: blur(10px);

    transition: filter $animation-speed;

    &.lazyloaded {
        filter: blur(0);
    }
}

.image__wrapper {
    display: block;
    overflow: hidden;

    &--has-link:hover .image {
        transform: scale(1.05);
    }
}
