.theme__tertiary {
    color: $color-secondary-100;

    background-color: $color-tertiary;

    &.global--marginal-column {
        &.picture-gallery {
            .splide__arrow {
                background-color: $color-tertiary;
            }
        }
    }

    .expander__target {
        &::after {
            background: linear-gradient(transparent, $color-tertiary);
        }
    }

    .separator__btn {
        border: 2px solid $color-septenary;

        color: $color-septenary;

        background-color: $color-tertiary;

        &:hover {
            color: $color-tertiary;

            background-color: $color-septenary;
        }
    }

    .separator__line {
        background-color: $color-septenary;
    }
}

@include media-breakpoint-up(xl) {
    .theme__tertiary {
        &.head-slider__item-text {
            background-color: fade_out($color-tertiary, 0.2);
        }
    }
}
