[data-simplebar] {
    position: relative;

    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
}

.simplebar-wrapper {
    width: inherit;
    max-width: inherit;
    height: inherit;
    max-height: inherit;
    overflow: hidden;
}

.simplebar-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;

    width: auto !important;
    height: auto !important;
    margin: 0;
    padding: 0;
    overflow: hidden;

    direction: inherit;
}

.simplebar-offset {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    box-sizing: inherit !important;
    margin: 0;
    padding: 0;

    direction: inherit !important;

    resize: none !important;

    -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {    
    position: relative;

    display: block;
    box-sizing: border-box !important;
    width: auto;
    max-width: 100%; /* Not required for horizontal scroll to trigger */
    height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
    max-height: 100%; /* Needed for vertical scroll to trigger */

    direction: inherit;

    visibility: visible;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.simplebar-content::before,
.simplebar-content::after {
    display: table;

    content: " ";
}

.simplebar-placeholder {
    width: 100%;
    max-width: 100%;
    max-height: 100%;

    pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
    position: relative;
    z-index: -1;

    flex-basis: 0;
    flex-grow: inherit;
    flex-shrink: 0;
    float: left;
    box-sizing: inherit !important;
    width: 100%;
    max-width: 1px;
    height: 100%;
    max-height: 1px;
    margin: 0;
    padding: 0;
    overflow: hidden;

    pointer-events: none;
}

.simplebar-height-auto-observer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    display: block;
    box-sizing: inherit;
    width: 1000%;
    min-width: 1px;
    height: 1000%;
    min-height: 1px;
    overflow: hidden;

    opacity: 0;

    pointer-events: none;
}

.simplebar-track {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;

    overflow: hidden;

    pointer-events: none;
}

[data-simplebar].simplebar-dragging .simplebar-content {
    -webkit-touch-callout: none;

    user-select: none;
    pointer-events: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all;
}

.simplebar-scrollbar {
    position: absolute;
    right: 0;
    left: 0;

    min-height: 10px;
}

.simplebar-scrollbar::before {
    position: absolute;
    right: 2px;
    left: 2px;

    border-radius: 7px;

    background: $color-black;

    opacity: 0;

    transition: opacity linear $animation-speed / 1.65;

    content: "";
}

.simplebar-scrollbar.simplebar-visible::before {
    /* When hovered, remove all transitions from drag handle */
    opacity: 0.5;

    transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
    top: 0;

    width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
    top: 2px;
    bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
    left: 0;

    height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar::before {
    right: 2px;
    left: 2px;

    height: 100%;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    top: 2px;
    right: auto;
    left: 0;

    width: auto;
    min-width: 10px;
    height: 7px;
    min-height: 0;
}

/* Rtl support */
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0;
}

.hs-dummy-scrollbar-size {
    position: fixed;

    width: 500px;
    height: 500px;
    overflow-x: scroll;
    overflow-y: hidden;

    direction: rtl;

    visibility: hidden;
    opacity: 0;
}

.simplebar-hide-scrollbar {
    position: fixed;
    left: 0;

    overflow-y: scroll;

    visibility: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
