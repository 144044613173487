.simplebar-track {
    background-color: $color-secondary-400;

    &.simplebar-vertical {
        right: 7px;

        width: 3px;
    }

    &.simplebar-horizontal {
        bottom: 7px;

        display: none;

        height: 3px;
    }
}

.scrollbar-horizontal {
    .simplebar-track.simplebar-horizontal {
        display: block;
    }
}

.simplebar-scrollbar {
    background-color: $color-white;

    &::after {
        display: none;
    }
}
