.copy {
    color: $color-white;

    font-weight: $font-weight-light;

    p,
    ul,
    ol {
        margin-bottom: 20px;

        line-height: 28px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        padding-left: 18px;
    }

    ol {
        padding-left: 20px;
    }

    li {
        margin-bottom: 7px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .copy {
        font-size: 22px;
        line-height: 32px;

        p {
            font-size: 22px;
            line-height: 32px;
        }
    }
}


@include media-breakpoint-up(xl) {
    .copy {
        p,
        ul,
        ol {
            margin-bottom: 20px;
        }
    }
}
