.navigation__list {
    display: flex;
    flex-wrap: wrap;

    margin: 0 -15px;
    padding: 0;

    list-style: none;
}

.navigation__link {
    display: block;
    padding: 15px 15px 11px;

    text-transform: uppercase;

    transition: opacity $animation-speed;

    &--active,
    &:hover,
    &.js-nested-menu--active {
        font-weight: $font-weight-medium;

        opacity: 1;
    }
}

.navigation__list-item {
    &--active {
        color: $color-white;
        font-weight: $font-weight-bold;
    }
}

.navigation {
    font-size: 20px;

    &--meta {
        font-size: 16px;

        .navigation__list {
            margin: 0 -10px;
        }

        .navigation__link {
            padding: 10px;
        }
    }
}

@media screen and (min-width: 1450px) {
    .navigation__list {
        margin: 0 -20px;
    }

    .navigation__link {
        padding: 15px 20px 11px;
    }
}
