.mobile-navigation {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 11;

    width: 100%;
    max-width: 375px;
    height: 100vh;

    color: $color-white;
    font-size: 18px;
    font-family: $font-family-base;

    background-color: $color-septenary-100;

    transform: translateY(-100vh);

    transition: transform $animation-speed * 2;


    &--collapsed {
        transform: translateY(0);
    }

    .link-icon {
        position: relative;

        display: flex;
        align-items: center;
    
        font-family: $font-family-base;
        text-decoration: none;
    }

    .link-icon__text {
        padding: 0 10px;

        font-size: 18px;
        line-height: 30px;
        text-transform: uppercase;

        opacity: 0.8;
    }

    .link-icon__icon {
        font-size: 14px;

        line-height: 30px;

        opacity: 0.8;

        .icon-plus {
            font-size: 26px;
        }
    }
}

.mobile-navigation__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    width: 100%;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.57);
    transform: translateY(-100vh);
    cursor: default;

    transition: transform 0.66s;

    &--collapsed {
        top: 80px;

        transform: translateY(0);
    }
}

.mobile-navigation__controls {
    display: flex;
    padding: 27px 20px 27px 30px;

    background-color: $color-secondary-50;

    .link-icon {

        text-decoration: none;

        .link-icon__text {
            color: $color-white;

            text-transform: none;
        }

        .link-icon__icon {
            color: $color-white;
        }
    }

    .js-mobile-navigation__back-button {
        cursor: default;

        &--fade {
            cursor: pointer;
        }
    }
}

.mobile-navigation__navigation-list {
    margin: 0;
    padding: 0;

    list-style: none;

    .link-icon {
        .link-icon__text {
            color: $color-white;
        }
        

        .link-icon__icon {
            color: $color-white;
        }

    }
}

.js-mobile-navigation__back-button {
    display: none;

    opacity: 0;

    transition: opacity $animation-speed;
}

.js-mobile-navigation__back-button--active {
    display: flex;
}

.js-mobile-navigation__back-button--fade {
    opacity: 1;
}

.js-mobile-navigation__close-button {
    margin-left: auto;

    .link-icon__icon {
        transform: rotate(45deg);
    }
}

.mobile-navigation__navigation-wrapper {
    max-height: calc(100vh - 85px);

    overflow: auto;

    &--sliding {
        overflow: hidden;
    }
}

.mobile-navigation__slide {
    position: absolute;
    top: 0;

    display: none;

    width: 100%;

    transition: transform $animation-speed;
}

.js-mobile-navigation__slide--active {
    position: relative;

    display: block;

    transform: translateX(0);
}

.js-mobile-navigation__slide--fade-out {
    display: block;

    transform: translateX(-500px);
}

.js-mobile-navigation__slide--fade-in {
    display: block;

    transform: translateX(500px);
}

.mobile-navigation__nav-item-wrapper {
    a {
        text-decoration: none;
    }
}

.mobile-navigation__nav-item-wrapper--main + .mobile-navigation__nav-item-wrapper--meta {
    border-top: 1px solid $color-white;
}

.mobile-navigation__nav-item {
    padding: 10px 20px;

    border-bottom: 1px solid fade_out($color-white, 0.3);

    text-decoration: none;

    &--active {
        font-weight: $font-weight-medium;
    }

    &--language {
        padding-left: 30px;

        border-top: 1px solid fade_out($color-white, 0.3);
        border-bottom: 0;

        font-size: 18px;

        .dropdown-toggle {
            opacity: 0.8;

            &::after {
                margin-left: 5px;

                font-size: 20px;
            }
        }

        .dropdown-menu {
            background-color: fade_out($color-black, 0.1);
        }
    }

    .link-icon__icon {
        margin-left: auto;

        color: $color-white;
        text-decoration: none;
    }

    .link-icon__text {
        color: $color-white;
    }
}

.mobile-navigation__slide--sub {
    .mobile-navigation__nav-item {
        padding-left: 40px;

        &--main {
            padding-left: 20px;
        }
    }
}

//@include media-breakpoint-up(md) {
//    .mobile-navigation__holder {
//        position: relative;
//    }
//
//    .mobile-navigation {
//        top: 20px;
//        bottom: auto;
//        left: -20px;
//
//        width: 375px;
//        height: auto;
//    }
//
//    .mobile-navigation__navigation-wrapper {
//        height: auto;
//        max-height: calc(100vh - 138px);
//    }
//
//    .mobile-navigation__controls {
//        padding: 15px 27px;
//    }
//}
