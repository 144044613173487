.subline {
    font-size: 20px;
    line-height: 32px;

    &--tertiary {
        color: $color-tertiary-150;
    }
}

@include media-breakpoint-up(md) {
    .subline {
        font-size: 20px;
    }
}
