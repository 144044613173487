// Primär - Terracotta
$color-primary-200: #b3745f !default;
$color-primary-400: #bf203d !default;
$color-primary-600: #c21c3b !default;
$color-primary-900: #db071a !default;

// Sekundär - Hellgrau
$color-secondary-50: #212024 !default;
$color-secondary-100: #2d2b32 !default;
$color-secondary-200: #575656 !default;
$color-secondary-300: #646464 !default;
$color-secondary-400: #6f6f6e !default;
$color-secondary-550: #979797 !default;
$color-secondary-600: #9d9d9d !default;
$color-secondary-700: #e0e0e0 !default;
$color-secondary-800: #ebebeb !default;
$color-secondary-900: #f7f7f7 !default;

// Tertiär - Beige
$color-tertiary-100: #d6c0a2 !default;
$color-tertiary-150: #bea895 !default;

// Quartär - Braun
$color-quaternary-100: #8d766b !default;
$color-quaternary-200: #a4866f !default;

// Quintär - Blau
$color-quinary-200: #61748b !default;

// Sextär - Lila
$color-senary-100: #534c56 !default;

// Septimär - Dunkelgrau
$color-septenary-100: #313036 !default;
$color-septenary-200: #38313b !default;

// Schwarz
$color-black: #000 !default;

// Weiß
$color-white: #fff !default;
