.news-details {
    .container {
        > .teaser__button-extension {
            padding-top: 20px;
        }
    }

    .headline__wrapper {
        padding-bottom: 5px;
    }

    .picture-gallery__splide-container {
        margin-top: 0;
        padding-top: 0;
    }

    .text-combination .copy {
        margin-bottom: 10px;
    }

    .text-image-combination {
        .btn__wrapper {
            display: block;
            margin-top: 0;
        }
    }

    .text-image-combination__row {
        display: flex;

        .teaser__button-extension {
            padding-top: 10px;
        }
    }

    .text-image-combination__image-container {
        order: 3;
        padding: 30px 0 0;
    }

    .text-image-combination__text-container {
        margin-top: 10px;
    }

    &__video {
        width: 100%;
        height: 180px;
    }
}

.news-details__brick {
    margin: 0;
    padding: 0;
    padding-top: 15px;

    &:last-child {
        padding-bottom: 30px;
    }

    ~ .news-details__brick {
        padding-top: 30px;
    }
}

@include media-breakpoint-up(md) {
    .news-details {
        .container {
            > .teaser__button-extension {
                padding: 40px 0 30px;
            }
        }

        .text-combination .copy {
            margin-bottom: 15px;
        }

        .text-image-combination__row {
            display: block;
        }

        .text-image-combination__text-container {
            margin-top: 25px;

            .teaser__button-extension {
                padding-top: 25px;
            }
        }

        &__video {
            width: 100%;
            height: 210px;
        }
    }

    .news-details__brick {
        margin: 0;
        padding: 0;

        &:last-child {
            padding-bottom: 40px;
        }

        ~ .news-details__brick {
            padding-top: 40px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .news-details {
        .container {
            > .teaser__button-extension {
                padding: 50px 0 40px;
            }
        }

        .text-image-combination__text-container {
            margin-top: 15px;

            .teaser__button-extension {
                padding: 45px 0 0;
            }
        }

        .text-combination .copy {
            margin-bottom: 45px;
        }

        &__video {
            width: 100%;
            height: 440px;
        }
    }

    .news-details__brick {
        &:last-child {
            padding-bottom: 100px;
        }

        ~ .news-details__brick {
            padding-top: 75px;
        }
    }
}
