.carousel-indicators .head-slider__dot {
    width: 26px;
    height: 0;
    margin: 0 5px;

    border: 2px solid $color-white;
    border-bottom: 1px solid $color-white;

    box-shadow: 0 2px 4px fade_out($color-black, 0.5);
    opacity: 1;

    transition: background-color $animation-speed;

    &.active {
        border: 2px solid $color-secondary-400;
        border-bottom: 1px solid $color-secondary-400;
    }
}

@include media-breakpoint-up(md) {
    .carousel-indicators .head-slider__dot {
        &.active {
            border: 2px solid $color-quaternary-200;
            border-bottom: 1px solid $color-quaternary-200;
        }
    }
}
