.btn__wrapper {
    position: relative;

    display: inline-block;

    width: 100%;
}

.btn {
    width: 100%;

    padding: 11px 30px;

    border: 2px solid $color-white;
    border-radius: 0;

    color: $color-white;
    font-size: 20px;
    line-height: 26px;
    text-decoration: none;

    &:hover {
        color: $color-secondary-100;

        background-color: $color-white;
    }

    &--primary {
        border: 0;

        background-color: $color-primary-400;

        &:hover {
            color: $color-primary-400;

            background-color: $color-white;
        }
    }
}

@include media-breakpoint-up(md) {
    .btn__wrapper {
        width: auto;
    }
}
