@font-face {
    font-weight: $font-weight-light;
    font-family: "Klavika";
    src: url("#{$font-path}klavika/KlavikaWebBasicLight.eot"),
        url("#{$font-path}klavika/KlavikaWebBasicLight.woff");
    font-display: swap;
}

@font-face {
    font-weight: $font-weight-regular;
    font-family: "Klavika";
    src: url("#{$font-path}klavika/KlavikaWebBasicRegular.eot"),
        url("#{$font-path}klavika/KlavikaWebBasicRegular.woff");
    font-display: swap;
}

@font-face {
    font-weight: $font-weight-medium;
    font-family: "Klavika";
    src: url("#{$font-path}klavika/KlavikaWebBasicMedium.eot"),
        url("#{$font-path}klavika/KlavikaWebBasicMedium.woff");
    font-display: swap;
}

@font-face {
    font-weight: $font-weight-light;
    font-family: "Klavika";
    font-style: italic;
    src: url("#{$font-path}klavika/KlavikaWebBasicLightItalic.eot"),
        url("#{$font-path}klavika/KlavikaWebBasicLightItalic.woff");
    font-display: swap;
}

@font-face {
    font-weight: $font-weight-regular;
    font-family: "Klavika";
    font-style: italic;
    src: url("#{$font-path}klavika/KlavikaWebBasicRegularItalic.eot"),
        url("#{$font-path}klavika/KlavikaWebBasicRegularItalic.woff");
    font-display: swap;
}

.font-primary {
    font-family: $font-primary-stack;

    &--light {
        font-weight: $font-weight-light;
    }

    &--regular {
        font-weight: $font-weight-regular;
    }

    &--medium {
        font-weight: $font-weight-medium;
    }

    &--italic {
        font-style: italic;
    }
}
