.link-list {
    margin-bottom: 0;
    padding-left: 0;
}

.link-list .link-list__item {
    margin-top: 1px;

    border-bottom: 1px solid $color-white;

    text-align: left;

    list-style: none;

    a {
        display: block;

        padding: 17px 20px 17px 35px;

        color: $color-white;

        font-size: 18px;
        line-height: 26px;
        text-transform: uppercase;

        text-decoration: none;
    }
}
