.error-page {
    min-height: calc(100vh - (80px));

    padding-top: 30px;

    .btn__wrapper {
        padding-top: 35px;
    }
}

.error-page__image {
    margin-top: 25px;
}

@include media-breakpoint-up(md) {
    .error-page {
        padding-top: 50px;

        .subline-wrapper {
            margin-bottom: 0;
        }

        .headline {
            font-size: 40px;
            line-height: 55px;
        }
    }

    .error-page__image {
        margin-top: -20px;
    }
}

@include media-breakpoint-up(xl) {
    .error-page {
        min-height: calc(100vh - (96px));

        padding-top: 90px;

        .btn__wrapper {
            padding-top: 45px;
        }
    }

    .error-page__image {
        max-width: 1120px;

        margin-top: -10px;
    }
}
