.accordion {
    margin-top: 1px;
}

.accordion__headline {
    position: relative;

    padding: 17px 60px 17px 20px;

    border-bottom: solid 2px $color-white;

    font-weight: 400;
    font-size: 20px;
    line-height: 26px;

    text-align: left;

    text-transform: uppercase;

    cursor: pointer;

    &.collapsed i::before {
        content: $icon-plus;
    }

    i {
        position: absolute;
        top: 17px;
        right: 20px;

        font-size: 24px;
    }
}

@include media-breakpoint-up(xl) {
    .accordion__headline {
        padding: 5px 0;

        border-bottom: 0;

        .link-list .link-list__item {
            border-bottom: 0;
        }
    }

    .link-list__item {
        .link-list__placeholder {
            margin-left: -4px;

            padding: 0 3px;
        }
    }
}
