.theme__primary {
    color: $color-white;

    background-color: $color-primary;

    &.global--marginal-column {
        &.picture-gallery {
            .splide__arrow {
                background-color: $color-primary;
            }
        }
    }

    .expander__target {
        &::after {
            background: linear-gradient(transparent, $color-primary);
        }
    }

    .separator__btn {
        background-color: $color-primary;

        &:hover {
            color: $color-primary;

            background-color: $color-secondary;
        }
    }
}

@include media-breakpoint-up(xl) {
    .theme__primary {
        &.head-slider__item-text {
            background-color: fade_out($color-primary, 0.2);
        }
    }
}
