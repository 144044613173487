.teaser__container {
    padding: 35px 0 40px;

    &--small-top {
        padding-top: 0;
    }

    &--small-bottom {
        padding-bottom: 0;
    }

    .headline__wrapper {
        padding: 0 0 10px;

        .headline {
            margin-bottom: 0;
        }
    }
}

.teaser {
    .headline__wrapper {
        padding: 0 0 10px;
    }
}

.teaser__wrapper {
    padding: 10px 0 20px;

    &:last-child {
        padding-bottom: 0;
    }
}

.teaser__image {
    width: 100%;
    margin: 0;

    .image {
        width: 100%;
    }

    p {
        margin: 0;
        padding: 5px 0 10px;

        color: $color-white;

        font-size: 16px;
        line-height: 22px;
    }
}

.teaser__image-link {
    .image__wrapper {
        overflow: hidden;

        &:hover {
            .image {
                transform: scale(1.03);
            }
        }

        .image {
            transform: scale(1);

            transition: transform $animation-speed;
        }
    }
}

.teaser__subline {
    margin: 0;
    padding: 5px 0 10px;

    color: $color-white;

    font-size: 16px;
    line-height: 22px;

    + .teaser__headline {
        padding-top: 0;
    }
}

.teaser__headline {
    margin: 0;
    padding: 10px 0 5px;

    color: $color-white;

    font-size: 24px;
    line-height: 34px;
}

.teaser__copy-extension {
    padding-bottom: 10px;

    font-size: 16px;
    line-height: 26px;
}

.teaser__button-extension {
    display: block;
    padding-top: 15px;

    text-align: center;

    .btn {
        width: 100%;
    }
}

.teaser__button-more {
    margin-top: 20px;
}

@include media-breakpoint-down(sm) {
    .expander__holder--padding-top.teaser__container {
        padding-top: 30px;
    }
}

@include media-breakpoint-up(md) {
    .teaser__container {
        padding: 50px 0 60px;

        &--small-top {
            padding-top: 0;
        }

        &--small-bottom {
            padding-bottom: 0;
        }

        &.global--marginal-column {
            .teaser__headline {
                padding-bottom: 5px;
            }

            .teaser__subline {
                padding-bottom: 10px;
            }

            .teaser__button-extension {
                padding-top: 15px;
            }
        }

        .headline__wrapper {
            padding: 0 0 20px;
        }
    }

    .teaser__headline {
        padding: 10px 0;
    }

    .teaser__subline {
        padding-bottom: 10px;
    }

    .teaser__copy-extension {
        line-height: 28px;
    }

    .teaser__button-extension {
        padding-top: 10px;

        text-align: left;

        .btn {
            width: auto;
        }
    }

    .teaser__button-more {
        margin-top: 0;
    }
}

@include media-breakpoint-up(xl) {
    .teaser__wrapper {
        padding: 15px 0;
    }

    .teaser__container {
        padding: 90px 0 100px;

        &--small-top {
            padding-top: 0;
        }

        &--small-bottom {
            padding-bottom: 0;
        }
    }

    .teaser {
        &.global--marginal-column {
            .teaser__subline:empty {
                padding-top: 15px;
            }
        }
    }

    .teaser__headline {
        padding-bottom: 15px;

        font-size: 30px;
        line-height: normal;
    }

    .teaser__copy-extension {
        margin-top: 0;

        font-size: 18px;
        line-height: 30px;
    }

    .teaser__button-extension {
        padding-top: 10px;
    }

    .teaser__button-more {
        margin-top: 35px;
    }
}
