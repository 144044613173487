.icon-navigation {
    display: flex;
    margin: -10px;

    font-size: 36px;

    .icon-navigation__item {
        text-decoration: none;
    }
}

.icon-navigation__item {
    display: block;

    padding: 10px;
}
