$picture-max-width: 100vw;

.picture-gallery {
    padding-top: 35px;
    padding-bottom: 30px;

    .headline__wrapper {
        padding-bottom: 0;

        .headline {
            margin-bottom: 0;
        }
    }

    .splide__arrow {
        border: solid 1px $color-white;
        border-radius: 0;

        font-size: 20px;

        background-color: rgba($color-white, 0.8);
        transform: translate(0, -100%);

        fill: $color-white;
    }

    .splide__arrow--prev {
        left: 0;
    }

    .splide__arrow--next {
        right: 0;
    }

    .splide__copy {
        margin: 0;

        text-align: center;
    }

    .splide__creator {
        margin: 0;

        text-align: center;
    }

    &--16-9 {
        .splide__arrow {
            top: calc(#{$picture-max-width} / 2 * 9 / 16 + 20px);
        }
    }

    &--21-9 {
        .splide__arrow {
            top: calc(#{$picture-max-width} / 2 * 9 / 21 + 20px);
        }
    }

    &--30-9 {
        .splide__arrow {
            top: calc(#{$picture-max-width} / 2 * 9 / 30 + 20px);
        }
    }
}

.picture-gallery__splide-container {
    max-width: 100%;

    &.container {
        padding: 5px 0 0;
    }

    > .col-12 {
        padding: 0;
    }
}

.picture-gallery__slide-img {
    width: 100%;
    height: auto;
    margin-bottom: 5px;
}

.picture-gallery__slide-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.picture-gallery__thumbnails {
    display: none;
}

@include media-breakpoint-up(md) {
    $picture-max-width: map-get($container-max-widths, md) - 2 * 60px;
    $picture-max-width-marginal: (map-get($container-max-widths, md) - 60px) / 2 - 5px;

    .picture-gallery {
        padding: 50px 0 60px;

        .splide__arrow {
            width: 50px;
            height: 50px;

            border: solid 2px $color-white;

            font-size: 28px;

            &--prev {
                transform: translateX(30px);
            }

            &--next {
                transform: translateX(-30px);
            }
        }

        .splide__copy,
        .splide__creator {
            padding: 0 30px;
        }

        &--16-9 {
            .splide__arrow {
                top: calc(#{$picture-max-width} / 2 * 9 / 16 - 25px);
            }
        }

        &--21-9 {
            .splide__arrow {
                top: calc(#{$picture-max-width} / 2 * 9 / 21 - 25px);
            }
        }

        &--30-9 {
            .splide__arrow {
                top: calc(#{$picture-max-width} / 2 * 9 / 30 - 25px);
            }
        }
    }

    .picture-gallery__splide-container {
        max-width: map-get($container-max-widths, md);
        margin: 0 auto;
        padding: 10px 0 0;
    }

    .picture-gallery__slide-container {
        padding: 0 30px;
    }

    .picture-gallery__slide-img {
        padding: 0 30px;
    }

    .picture-gallery__thumbnails {
        display: block;
        padding: 15px 80px 0;

        /* stylelint-disable */
        // Verschachtelung durch Splide
        > .splide__track > .splide__list > .splide__slide {
            border: 0;

            &.is-active {
                .picture-gallery__slide-container {
                    filter: grayscale(0%);
                }
            }
        }
        /* stylelint-enable */

        .picture-gallery__slide-container {
            padding: 0;

            filter: grayscale(100%);

            transition: all ease-in-out $animation-speed;

            &:hover {
                filter: grayscale(0%);
            }
        }

        .picture-gallery__slide-img {
            margin: 0;
            padding: 0;
        }

        .splide__slide {
            display: flex;
            align-items: center;
        }

        .splide__arrow {
            top: calc(100% - 9px);
            bottom: -35px;

            border: 0;

            background-color: transparent;

            &--prev {
                transform: translate(22px, -100%);
            }

            &--next {
                transform: translate(-20px, -100%);
            }
        }

        .splide__list {
            display: flex;
        }
    }

    .picture-gallery.global--marginal-column {
        &.picture-gallery {
            &--16-9 {
                .splide__arrow {
                    top: calc(#{$picture-max-width-marginal} / 2 * 9 / 16 - 20px);
                }
            }

            &--21-9 {
                .splide__arrow {
                    top: calc(#{$picture-max-width-marginal} / 2 * 9 / 21 - 20px);
                }
            }

            &--30-9 {
                .splide__arrow {
                    top: calc(#{$picture-max-width-marginal} / 2 * 9 / 30 - 20px);
                }
            }
        }

        .picture-gallery__splide-container {
            padding: 0 30px;

            .col-12 {
                padding-right: 5px;
            }
        }

        .picture-gallery__slide-container {
            padding: 0;
        }

        .picture-gallery__slide-img {
            padding: 0;
        }

        .picture-gallery__thumbnails {
            display: none;
        }

        .splide__arrow {
            width: 40px;
            height: 40px;

            svg {
                width: 20px;
                height: 20px;
            }

            &--prev {
                transform: translateX(0);
            }

            &--next {
                transform: translateX(0);
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    $picture-max-width: map-get($container-max-widths, lg) - 2 * 60px;
    $picture-max-width-marginal: (map-get($container-max-widths, lg) - 60px) / 2 - 5px;

    .picture-gallery {
        &--16-9 {
            .splide__arrow {
                top: calc((#{$picture-max-width} / 2) * 9 / 16 - 25px);
            }
        }

        &--21-9 {
            .splide__arrow {
                top: calc((#{$picture-max-width} / 2) * 9 / 21 - 25px);
            }
        }

        &--30-9 {
            .splide__arrow {
                top: calc((#{$picture-max-width} / 2) * 9 / 30 - 25px);
            }
        }
    }

    .picture-gallery__thumbnails {
        .splide__arrow {
            top: calc(100% - 9px);
        }
    }

    .picture-gallery__splide-container {
        max-width: map-get($container-max-widths, lg);
    }

    .picture-gallery.global--marginal-column {
        &.picture-gallery {
            &--16-9 {
                .splide__arrow {
                    top: calc(#{$picture-max-width-marginal} / 2 * 9 / 16 - 20px);
                }
            }

            &--21-9 {
                .splide__arrow {
                    top: calc(#{$picture-max-width-marginal} / 2 * 9 / 21 - 20px);
                }
            }

            &--30-9 {
                .splide__arrow {
                    top: calc(#{$picture-max-width-marginal} / 2 * 9 / 30 - 20px);
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    $picture-max-width: (map-get($container-max-widths, xl) - 120px) * 0.8;
    $picture-max-width-marginal: (map-get($container-max-widths, xl) - 60px - 10px) * 0.66;

    .picture-gallery {
        .splide__copy {
            padding: 0 10%;
        }

        .splide__creator {
            padding: 0 10%;
        }

        .splide__arrow {
            width: 60px;
            height: 60px;

            svg {
                width: 30px;
                height: 30px;
            }
        }

        &--16-9 {
            .splide__arrow {
                top: calc(#{$picture-max-width} / 2 * 9 / 16 - 30px);
            }
        }

        &--21-9 {
            .splide__arrow {
                top: calc(#{$picture-max-width} / 2 * 9 / 21 - 30px);
            }
        }

        &--30-9 {
            .splide__arrow {
                top: calc(#{$picture-max-width} / 2 * 9 / 30 - 30px);
            }
        }
    }

    .picture-gallery__splide-container {
        max-width: map-get($container-max-widths, xl);
        margin-top: 40px;

        &.container {
            padding: 0 30px;
        }
    }

    .picture-gallery__slide-img {
        padding: 0 10%;
    }

    .picture-gallery__thumbnails {
        margin: 15px 90px 0;
        padding: 0;

        .splide__arrow {
            top: auto;
            bottom: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 110px;

            transform: translate(0, 0);

            transition: all ease-in-out $animation-speed;

            &:hover {
                background-color: $color-quaternary-100;
                opacity: 1;

                fill: $color-white;
            }
        }

        .splide__list {
            display: flex;
        }
    }

    .picture-gallery.global--marginal-column {
        &.picture-gallery {
            &--16-9 {
                .splide__arrow {
                    top: calc(#{$picture-max-width-marginal} / 2 * 9 / 16 - 30px);
                }
            }

            &--21-9 {
                .splide__arrow {
                    top: calc(#{$picture-max-width-marginal} / 2 * 9 / 21 - 30px);
                }
            }

            &--30-9 {
                .splide__arrow {
                    top: calc(#{$picture-max-width-marginal} / 2 * 9 / 30 - 30px);
                }
            }
        }

        .picture-gallery__splide-container .col-12 {
            padding-right: 10px;
        }

        .splide__arrow {
            width: 60px;
            height: 60px;
        }

        .picture-gallery__thumbnails {
            display: block;

            .picture-gallery__splide-container {
                margin-top: 20px;
            }

            .splide__arrows {
                position: relative;
            }

            .splide__arrow {
                top: 100%;
                bottom: 0;

                display: flex;
                align-items: center;
                justify-content: center;
                width: 80px;
                height: 110px;

                transition: all ease-in-out $animation-speed;

                &:hover {
                    background-color: $color-quaternary-100;
                    opacity: 1;

                    fill: $color-white;
                }

                &--prev {
                    transform: translateX(-90px);
                }

                &--next {
                    transform: translateX(90px);
                }
            }
        }
    }
}

@media (min-width: #{map-get($grid-breakpoints, xl)}) and (max-width: #{map-get($container-max-widths, xl)}) {
    .picture-gallery {
        &--16-9 {
            .splide__arrow {
                top: calc((100vw - 120px) * 0.8 / 2 * 9 / 16 - 30px);
            }
        }

        &--21-9 {
            .splide__arrow {
                top: calc((100vw - 120px) * 0.8 / 2 * 9 / 21 - 30px);
            }
        }

        &--30-9 {
            .splide__arrow {
                top: calc((100vw - 120px) * 0.8 / 2 * 9 / 30 - 30px);
            }
        }
    }

    .picture-gallery__thumbnails {
        .splide__arrow {
            top: auto;
        }
    }

    .picture-gallery.global--marginal-column {
        &.picture-gallery {
            &--16-9 {
                .splide__arrow {
                    top: calc(((100vw - 60px) * 0.66 - 10px) / 2 * 9 / 16 - 20px);
                }
            }

            &--21-9 {
                .splide__arrow {
                    top: calc(((100vw - 60px) * 0.66 - 10px) / 2 * 9 / 21 - 20px);
                }
            }

            &--30-9 {
                .splide__arrow {
                    top: calc(((100vw - 60px) * 0.66 - 10px) / 2 * 9 / 30 - 20px);
                }
            }
        }

        .picture-gallery__thumbnails {
            .splide__arrow {
                top: 100%;
            }
        }
    }
}
