.theme__quaternary {
    color: $color-white;

    background-color: $color-quaternary;

    &.global--marginal-column {
        &.picture-gallery {
            .splide__arrow {
                background-color: $color-quaternary;
            }
        }
    }

    .expander__target {
        &::after {
            background: linear-gradient(transparent, $color-quaternary);
        }
    }

    .separator__btn {
        background-color: $color-quaternary;

        &:hover {
            color: $color-quaternary;

            background-color: $color-secondary;
        }
    }
}

@include media-breakpoint-up(xl) {
    .theme__quaternary {
        &.head-slider__item-text {
            background-color: fade_out($color-quaternary, 0.2);
        }
    }
}
