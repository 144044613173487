.text-image-combination {
    padding: 50px 0 60px;

    .copy {
        margin-top: 0;

        p {
            font-size: 18px;
        }
    }

    .btn__wrapper {
        display: inline-block;
        margin-top: 15px;

        &,
        .btn {
            width: 100%;
        }

        &--mobile {
            display: inline-block;
        }
    }

    .headline__wrapper {
        padding-bottom: 20px;
    }
}

.text-image-combination__row {
    display: block;
}

.text-image-combination__subline {
    margin-bottom: 10px;
}

.text-image-combination__image-container {
    display: flex;
    flex-direction: column;

    .image--blur-up {
        align-self: center;
    }
}

.text-image-combination__image-text {
    font-size: 16px;
    line-height: 22px;
}

.text-image-combination__text-container {
    margin-top: 15px;
}

.text-image-combination__date {
    display: none;
}

@include media-breakpoint-down(sm) {
    .text-image-combination {
        &.expander__holder--padding-top {
            padding-top: 60px;
        }

        &.expander__holder--padding-bottom {
            padding-bottom: 50px;
        }

        .expander__target--closed {
            height: 250px !important;
        }
    }
}

@include media-breakpoint-up(md) {
    .text-image-combination {
        padding: 50px 0 60px;

        &::after {
            display: block;
            clear: both;

            content: "";
        }

        &--image-left {
            .text-image-combination__image-container {
                float: left;
                padding-right: $grid-gutter-width;
            }
        }

        &--image-right {
            .text-image-combination__image-container {
                float: right;
                padding-left: $grid-gutter-width;
            }
        }

        .btn__wrapper {
            display: inline-block;
            margin-top: 30px;

            &,
            .btn {
                width: auto;
            }

            &--mobile {
                display: none;
            }
        }

        .copy {
            margin-top: 0;
        }

        .subline__wrapper {
            margin-bottom: -5px;
            margin-left: 2px;
        }

        .headline__wrapper {
            padding-bottom: 10px;
        }

        .headline {
            font-size: 40px;
        }

        &:not(.global--marginal-column) {
            .text-image-combination__image-container {
                padding-top: 10px;
            }
        }
    }

    .text-image-combination__headline {
        margin-bottom: 20px;
    }

    .text-image-combination__subline {
        margin-bottom: 30px;
    }

    .text-image-combination__image-container {
        position: relative;
        z-index: 2;

        display: block;
        margin: 0;
    }

    .text-image-combination__text-container {
        position: relative;
        z-index: 1;
    }

    .text-image-combination__image-text {
        margin-top: 5px;
    }

    .global--marginal-column {
        &.text-image-combination :not(.text-image-combination--image-portrait) {
            .text-image-combination__image-container {
                max-width: 100%;
                max-height: 100%;
            }

            .text-image-combination__text-container {
                max-width: 100%;
                margin-left: 0;
            }
        }

        .text-image-combination__image-text {
            margin-bottom: 15px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .text-image-combination {
        padding: 92px 0 100px;

        .headline__wrapper {
            padding-bottom: 0;
        }

        .subline__wrapper {
            margin-bottom: -10px;
        }

        .btn__wrapper {
            margin-top: 40px;
        }
    }

    .copy {
        margin-top: 30px;
    }

    .text-image-combination__headline,
    .text-image-combination__subline {
        margin-bottom: 35px;
    }

    .text-image-combination__date {
        display: block;

        font-size: 16px;
        line-height: 26px;
    }
}
