// Primär
.color-primary-200 {
    color: $color-primary-200;
}

.color-primary-400 {
    color: $color-primary-400;
}

.color-primary-600 {
    color: $color-primary-600;
}

.color-primary-900 {
    color: $color-primary-900;
}

// Sekundär
.color-secondary-50 {
    color: $color-secondary-50;
}

.color-secondary-100 {
    color: $color-secondary-100;
}

.color-secondary-200 {
    color: $color-secondary-200;
}

.color-secondary-300 {
    color: $color-secondary-300;
}

.color-secondary-400 {
    color: $color-secondary-400;
}

.color-secondary-600 {
    color: $color-secondary-600;
}

.color-secondary-800 {
    color: $color-secondary-800;
}

.color-secondary-900 {
    color: $color-secondary-900;
}

// Tertiär
.color-tertiary-100 {
    color: $color-tertiary-100;
}

.color-tertiary-150 {
    color: $color-tertiary-150;
}

// Quartär - Braun
.color-quaternary-100 {
    color: $color-quaternary-100;
}

.color-quaternary-200 {
    color: $color-quaternary-200;
}

// Quintär - Blau
.color-quinary-200 {
    color: $color-quinary-200;
}

// Sextär - Lila
.color-senary-100 {
    color: $color-senary-100;
}

// Septimär - Dunkelgrau
.color-septenary-100 {
    color: $color-septenary-100;
}

.color-septenary-200 {
    color: $color-septenary-200;
}

// Schwarz
.color-black {
    color: $color-black;
}

// Weiß
.color-white {
    color: $color-white;
}
