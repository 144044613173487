.marginal-column {
    .text-image-combination {
        padding: 75px 0 30px;
    }

    .teaser__container {
        .subline__wrapper {
            margin: 0;
        }
    }
}

.marginal-column + .marginal-column {
    margin-top: 20px;
}

@include media-breakpoint-down(sm) {
    .marginal-column {
        &--expander-overlay {
            .teaser__container {
                padding-top: 75px;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .global--marginal-column > .container > * {
        max-width: 50% !important;

        padding-right: 10px;
    }

    .marginal-columns {
        position: fixed;
        top: 80px;
        right: calc((100% - #{map-get($container-max-widths, md)}) * 0.5 + 30px);
        z-index: 49;

        width: 100%;
        max-width: calc((#{map-get($container-max-widths, md)} - 60px) * 0.5 - 10px);
    }

    .marginal-column {
        margin-top: 60px;
        padding: 30px;

        background-color: $color-white;

        + .marginal-column {
            margin-top: 30px;
        }

        .headline {
            font-size: 32px;
        }

        .copy {
            margin-bottom: 30px;

            font-size: 18px;
            line-height: 28px;

            p {
                font-size: 18px;
                line-height: 28px;
            }
        }

        .text-combination {
            padding: 0;

            .headline__wrapper {
                margin-bottom: 35px;
                padding-bottom: 0;
            }

            .subline__wrapper {
                margin-bottom: 5px;
            }
        }

        .text-combination__copy {
            margin-top: 0;
        }

        .text-image-combination {
            padding: 0;

            .container {
                padding: 0;
            }

            .headline__wrapper {
                padding: 0;
            }

            .subline__wrapper {
                margin: 0;
            }

            .btn__wrapper {
                margin-top: 0;
            }
        }

        .text-image-combination__image-container {
            max-width: 100%;
            margin-top: 0;

            +.text-image-combination__text-container {
                margin-top: 15px;
            }
        }

        .text-image-combination__text-container {
            margin: 0;
        }

        .teaser__container {
            padding: 0;

            background-color: $color-white;

            .container {
                padding: 0;
                padding-top: 5px;
            }

            .col-md-6 {
                flex: 0 0 100%;
                max-width: 100%;
            }

            .headline__wrapper {
                padding-bottom: 10px;
            }
        }

        .teaser__copy-extension {
            margin-bottom: 5px;
            padding: 0;
        }
    }

    .marginal-column__container {
        padding: 0;
    }
}

@include media-breakpoint-up(lg) {
    .marginal-columns {
        right: calc((100% - #{map-get($container-max-widths, lg)}) * 0.5 + 30px);

        max-width: calc((#{map-get($container-max-widths, lg)} - 60px) * 0.5 - 10px);
    }
}

@include media-breakpoint-up(xl) {
    .global--marginal-column > .container > * {
        max-width: 66% !important;

        padding-right: 0;
    }

    .marginal-columns {
        top: 98px;
    }

    .marginal-column {
        margin-top: 90px;

        + .marginal-column {
            margin-top: 50px;
        }

        .copy {
            margin-bottom: 25px;
        }

        .headline {
            font-size: 32px;
            line-height: normal;
        }

        .subline {
            font-size: 20px;
            line-height: 32px;
        }

        .text-image-combination__image-container {
            margin-bottom: 15px;
        }

        .teaser__container {
            .headline__wrapper {
                padding-bottom: 5px;
            }
        }

        .teaser__headline {
            padding-bottom: 5px;
        }

        .teaser__subline {
            font-size: 16px;
            line-height: normal;
        }

        .teaser__copy-extension {
            margin: 0 0 25px;
        }

        .teaser__button-extension {
            padding-top: 0;
        }
    }
}

@include media-breakpoint-between(xl, xxl) {
    .marginal-columns {
        right: 30px;

        max-width: calc(33% - 30px);
    }
}

@media (min-width: calc(#{map-get($grid-breakpoints, xxl)} + 60px)) {
    .marginal-columns {
        right: calc((100% - #{map-get($container-max-widths, xl)}) / 2 + 30px);

        max-width: calc((#{map-get($container-max-widths, xl)} - 60px) / 3 - 10px);
    }
}
