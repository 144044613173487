//Fonts
$font-path: "./../../../font/" !default;

$font-primary-stack: "Klavika", Sans-Serif !default;

$color-primary: $color-primary-200;
$color-secondary: $color-secondary-900 !default;
$color-tertiary: $color-tertiary-100 !default;
$color-quaternary: $color-quaternary-100 !default;
$color-quinary: $color-quinary-200 !default;
$color-senary: $color-senary-100 !default;
$color-septenary: $color-septenary-100 !default;

$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;

$animation-speed: 0.33s !default;

$mega-menu-columns: 3;

$navigation-z-index: 1000;

$icon-arrow-menu-right: "\e905";
$icon-arrow-menu-down: "\e906";
$icon-plus: "\e908";
$icon-minus: "\e909";
