.dropdown {
    position: relative;

    display: flex;

    a:not([href]),
    a:not([href]):hover {
        color: inherit;
        text-decoration: none;
    }
}

.dropdown-toggle {
    display: flex;
    align-items: center;

    cursor: pointer;
}

.dropdown-toggle::after {
    @extend %icon;

    margin-left: 10px;

    border: 0;

    transform: rotate(0deg);

    transition: transform $animation-speed;

    content: $icon-arrow-menu-down;
}

.dropdown.show {
    .dropdown-toggle::after {
        transform: rotate(180deg);
    }
}

.dropdown-menu {
    min-width: 130px;
    border-radius: 0;

    color: fade_out($color-white, 0.3);

    background-color: fade_out($color-black, 0.3);

    &::before {
        position: absolute;
        left: 15px;

        display: block;
        width: 0;
        border: 10px solid transparent;

        content: "";
    }

    &[x-placement="bottom-start"]::before {
        top: -20px;

        border-bottom-color: fade_out($color-black, 0.3);
    }

    &[x-placement="top-start"]::before {
        bottom: -20px;

        border-top-color: fade_out($color-black, 0.3);
    }
}

.dropdown-item {
    color: inherit;

    transition: color $animation-speed;
}

@include media-breakpoint-up(sm) {
    .dropdown-item:hover {
        color: $color-white;

        background-color: transparent;
    }
}
