.headline__wrapper {
    padding: 0 0 15px;
}

.headline {
    margin-bottom: 15px;

    font-weight: $font-weight-light;
    font-size: 32px;
    line-height: normal;
}

@include media-breakpoint-up(md) {
    .headline__wrapper {
        padding: 0 0 20px;
    }

    .headline {
        margin-bottom: 20px;

        font-size: 30px;
        line-height: 40px;
    }
}

@include media-breakpoint-up(xl) {
    .headline {
        font-size: 40px;
        line-height: normal;
    }
}
