@import "app/abstracts/colors";
@import "app/abstracts/settings";

@import "app/vendor-extensions/bootstrap-settings";

@import "~bootstrap/scss/bootstrap";
@import "~aos/dist/aos.css";
@import "~@splidejs/splide/dist/css/splide.min.css";

@import "app/vendor-extensions/bootstrap-carousel";

@import "app/base/defaults";
@import "app/base/icons";
@import "app/base/colors";
@import "app/base/fonts";
@import "app/base/links";
@import "app/base/lists";
@import "app/base/paragraph";
@import "app/base/typography";
@import "app/base/headline";
@import "app/base/subline";

@import "app/components/buttons";
@import "app/components/image";
@import "app/components/copy";
@import "app/components/head-slider";
@import "app/components/expander";
@import "app/components/text-image-combination";
@import "app/components/navigation";
@import "app/components/link-icon";
@import "app/components/service-badge";
@import "app/components/submenu";
@import "app/components/accordion";
@import "app/components/link-list";
@import "app/components/yt-video";
@import "app/components/separator";
@import "app/components/simplebar";
@import "app/components/search_layer";
@import "app/components/mobile-navigation";
@import "app/components/dropdown";
@import "app/components/icon-navigation";
@import "app/components/scrollbar";
@import "app/components/childlisting";
@import "app/components/picture-gallery";

@import "app/layout/footer";
@import "app/layout/teaser";
@import "app/layout/grid";
@import "app/layout/text-combination";
@import "app/layout/marginal-column";
@import "app/layout/mega-menu";
@import "app/layout/header";

@import "app/themes/primary";
@import "app/themes/secondary";
@import "app/themes/tertiary";
@import "app/themes/quaternary";
@import "app/themes/quinary";
@import "app/themes/senary";
@import "app/themes/septenary";
// Muss als letztes in themes eingebunden werden, um Theme-Elemente zu überschreiben
@import "app/themes/theme";

@import "app/page/default";
@import "app/page/error-page";
@import "app/page/news-details";
