.head-slider {
    position: relative;

    overflow: hidden;

    .carousel-inner {
        overflow: visible;
    }
}

.head-slider__item {
    z-index: 3;

    width: 100%;

    .head-slider__item-text {
        z-index: 16;
    }
}

.head-slider__item-image {
    color: $color-white;

    img {
        width: 100%;
    }
}

.head-slider__item-image-data {
    margin-top: 8px;
}

.head-slider__item-author {
    padding: 30px 25px 20px;

    font-size: 16px;
}

.head-slider__item-text {
    padding: 25px 25px 30px;

    .teaser__headline {
        padding-bottom: 0;

        font-weight: $font-weight-medium;
        font-size: 24px;

        + .teaser__btn {
            margin-top: 20px;
        }

        &.subline {
            font-weight: $font-weight-light;
            font-size: 20px;
        }
    }

    .teaser__copy {
        margin-bottom: 0;

        + .teaser__btn {
            margin-top: 20px;
        }
    }

    .teaser__btn {
        width: 100%;

        text-align: center;
    }

    .btn {
        width: 100%;
    }

    .container {
        max-width: 100%;
        padding: 0;
    }
}

.head-slider__item-image + .head-slider__item-text {
    padding-top: 0;
}

.head-slider__dots {
    top: calc((100vw * 9 / 21) - 20px);
    bottom: auto;

    margin: 0;
}

.head-slider__control {
    bottom: auto;
    z-index: 5;

    display: none;
    width: 60px;
    height: 60px;
    margin-right: 30px;
    padding: 0;

    color: $color-white;

    font-size: 28px;

    opacity: 1;

    &:hover {
        background-color: transparent;
    }

    &:focus {
        box-shadow: none;
    }

    &--prev {
        margin-left: 30px;
    }

    &--next {
        margin-right: 30px;
    }
}

@include media-breakpoint-up(md) {
    .head-slider__item {
        z-index: 3;

        transition: $animation-speed * 3 transform;
        transition-delay: $animation-speed / 2;

        &.has-text {
            .head-slider__item-image {
                .container {
                    padding-top: 50px;
                }
            }
        }

        .head-slider__item-text {
            z-index: 16;

            opacity: 0;

            transition: opacity $animation-speed * 1.5;
        }

        .head-slider__item-image .container {
            transition: opacity $animation-speed * 1.5;
        }

        &.active {
            .head-slider__item-text,
            .head-slider__item-image .container {
                opacity: 1;
            }
        }

        &.carousel-item-left,
        &.carousel-item-right {
            .head-slider__item-text,
            .head-slider__item-image .container {
                opacity: 0;
            }
        }

        &.carousel-item-next,
        &.carousel-item-prev {
            z-index: 1;

            transition: $animation-speed * 3 transform;
        }
    }

    .head-slider__item-image {
        color: $color-primary;
    }

    .head-slider__item-text {
        width: 100%;
        padding: 0 0 40px;
        border-left: 0;

        .teaser {
            position: relative;
            z-index: 20;

            overflow: auto;
        }

        .teaser__headline {
            padding-top: 0;
        }

        .teaser__copy,
        .teaser__copy p {
            font-size: 16px;
            line-height: 26px;
        }

        .teaser__btn,
        .btn {
            width: auto;
        }
    }

    .head-slider__dots {
        top: calc(100vw * 9 / 21 - 20px);

        justify-content: flex-start;
        margin: 0 auto;
    }

    .head-slider__control {
        top: calc(50vw * 9 / 21 - 30px);

        display: flex;

        border: 2px solid $color-tertiary-150;

        text-decoration: none;

        .icon-arrow-left,
        .icon-arrow-right {
            color: $color-tertiary-150;
            font-size: 33px;
        }
    }

    .head-slider__dots,
    .head-slider__item-author,
    .head-slider__item-text .container {
        max-width: map-get($container-max-widths, md);
        padding-right: 30px;
        padding-left: 30px;
    }

    .head-slider__item-author {
        margin: 0 auto;
        padding-top: 40px;
    }
}

@include media-breakpoint-up(lg) {
    .head-slider__dots,
    .head-slider__item-author,
    .head-slider__item-text .container {
        max-width: map-get($container-max-widths, lg);
    }
}

@include media-breakpoint-up(xl) {
    .head-slider__item-image + .head-slider__item-text {
        padding: 30px 30px 40px 50px;
    }

    .head-slider__control {
        top: calc(100vw * 9 / 21 / 2 - 25px);
    }

    .head-slider__dots {
        top: calc(100vw * 9 / 21 - 25px);
    }

    .head-slider__dots,
    .head-slider__item-author {
        max-width: map-get($container-max-widths, xl);
    }

    .head-slider__item-text {
        position: absolute;
        right: 0;
        bottom: calc(78px + 115px);

        .container {
            padding: 0;
        }

        .teaser__headline {
            &.subline {
                padding-bottom: 4px;
            }
        }
    }

    .teaser__copy {
        &.copy {
            margin-top: 20px;
        }
    }

    .head-slider__item-author {
        padding: 15px 30px;
    }

    [class*="theme__"].head-slider__item-image {
        color: $color-secondary-100;

        background-color: $color-white;
    }
}

@media (min-width: #{map-get($grid-breakpoints, xl)}) and (max-width: #{map-get($container-max-widths, xl)}) {
    .head-slider__item-text {
        right: 30px;
    }
}

@include media-breakpoint-up(xl, $container-max-widths) {
    .head-slider__item-text {
        right: calc((100% - #{map-get($container-max-widths, xl)} + 60px) / 2);
    }
}

@include media-breakpoint-down(lg) {
    .head-slider__item-text {
        width: 100% !important;
    }
}
