.search-layer {
    position: absolute;
    top: -100vw;
    right: 0;
    z-index: 9999;

    width: 0;
    height: 100vh;
    overflow-y: auto;

    background-color: fade_out($color-black, $animation-speed * 1.3);

    transition: right $animation-speed * 2, width $animation-speed * 2;
  
    &.is-active {
        top: 0;

        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-right: 15px;
        padding-left: 15px;

        transition: top $animation-speed, width $animation-speed;
    }
}
  
.search-layer-content {
    position: relative;
    top: 100px;
    z-index: 1;

    display: flex;
    flex-wrap: wrap;
    max-width: 540px;
    margin: auto;
}
  
.search-field-wrapper {
    width: 100%;
    overflow: hidden;

    opacity: 0;
  
    &.is-active {
        opacity: 1;
    }
}
 
.search-form {
    position: relative;

    display: flex;

    padding: 0;
}
  
.search-field {
    width: calc(100% - 70px);
    height: 50px;
    margin: 0;
    padding: 12px 15px;
    border: 0;

    color: $color-white;
    font-size: 18px;
    font-family: $font-family-base;
    line-height: 18px;

    background-color: $color-secondary-400;
    outline: none;

    &::placeholder {
        height: 50px;

        color: $color-white;
        font-size: 18px;
        line-height: 18px;
    }
  
    &:focus {
        border: 0;

        color: $color-black;

        background-color: $color-white;

        outline: none;
    }
}
  
.search-btn {
    width: 50px;
    height: 50px;
    margin-left: 10px;
    border: 0;
  
    i {
        font-size: 28px;

        cursor: pointer;
    }
  
    .btn__text {
        position: absolute;
        left: 12px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}
  
.autocomplete-suggestions,
.search-suggestions {
    width: 100%;
    max-height: none !important;
    overflow: hidden;

    font-size: 16px;
    font-family: $font-family-base;

    transform: translateY(-0.625rem);
    opacity: 0;

    transition: max-height $animation-speed, opacity $animation-speed, transform $animation-speed, padding $animation-speed;
  
    &.autocomplete-suggestions,
    &.is-active {
        transform: translateY(0);
        opacity: 1;
  
        &:empty {
            opacity: 0;
        }
    }
}
  
.autocomplete-suggestion,
.search-suggestion-item {
    display: block;
    width: calc(100% - 70px);
    height: 40px;
    margin: 2px 0;
    padding: 12px 15px;

    color: $color-white;
    font-size: 18px;
    font-family: $font-family-base;
    line-height: 18px;

    background-color: $color-secondary-400;
    cursor: pointer;
  
    &:hover {
        color: $color-secondary-400;
    }
  
    &.autocomplete-suggestion {
        width: 100%;
    }
}
  
@include media-breakpoint-up(sm) {
    .search-layer-content {
        max-width: 540px;
    }
  
    .search-field-wrapper {
        padding: 0 15px;
    }
}
  
@include media-breakpoint-up(md) {
    .search-layer-content {
        max-width: 720px;
    }
}
  
@include media-breakpoint-up(lg) {
    .search-layer-content {
        max-width: 960px;
    }
}
  
@include media-breakpoint-up(xl) {
    .search-layer-content {
        top: 144px;

        max-width: 1600px;

        padding: 0;
    }
  
    .search-field-wrapper {
        padding: 0;
    }
  
    .search-field {
        width: calc(100% - 64px);
        overflow: hidden;
        
        opacity: 0;
  
        transition: max-width $animation-speed, opacity $animation-speed;
  
        &.is-active {
            opacity: 1;
        }
    }

    .search-suggestion-item {
        width: calc(100% - 64px);
    }
}
