ul,
ol {
    padding-left: 16px;
    
    font-size: 16px;
    font-family: $font-primary-stack;
    line-height: 26px;
}

@include media-breakpoint-up(xl) {
    ul,
    ol {
        font-size: 18px;
        line-height: 30px;
    }
}
