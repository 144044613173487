.separator {
    padding-top: 10px;
    padding-bottom: 10px;

    text-align: center;

    &--has-hover:hover {
        cursor: pointer;
    }
}

.separator__line {
    height: 2px;
    margin: 20px 0;

    background-color: $color-secondary;
}

.separator__btn-wrapper {
    position: relative;
    top: -19px;
}

.separator__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;

    border: 2px solid $color-secondary;

    color: $color-secondary;

    .icon-arrow-up {
        font-size: 25px;
    }
}

@include media-breakpoint-up(md) {
    .separator {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .separator__btn-wrapper {
        top: -29px;
    }

    .separator__btn {
        width: 50px;
        height: 50px;

        .icon-arrow-up {
            font-size: 30px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .separator {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .separator__btn {
        width: 61px;
        height: 61px;

        .icon-arrow-up {
            font-size: 36px;
        }
    }
}
