.text-combination {
    padding: 50px 0 60px;

    &.expander__holder--padding-top {
        padding-top: 60px;
    }

    &.expander__holder--padding-bottom {
        padding-bottom: 50px;
    }

    .btn__wrapper {
        display: block;
    }

    .btn {
        width: 100%;
    }

    .copy {
        margin-bottom: 35px;
    }
}

.text-combination__subline {
    margin-bottom: 10px;
}

@include media-breakpoint-up(md) {
    .text-combination {
        padding: 50px 0;

        .btn__wrapper {
            display: inline-block;
        }

        .headline__wrapper {
            margin-bottom: 20px;
            padding: 0;
        }
    }

    .text-combination__headline {
        margin-bottom: 20px;
    }

    .text-combination__subline {
        margin-bottom: 30px;
    }

    .text-combination__copy {
        &--columns-2 {
            column-count: 2;
            column-gap: 30px;
        }
    }

    .global--marginal-column .text-combination__copy--columns-2 {
        column-count: 1;
    }
}

@include media-breakpoint-up(xl) {
    .text-combination {
        padding: 90px 0 100px;

        .text-combination__headline,
        .text-combination__subline {
            margin-bottom: 35px;
        }
    }

    .global--marginal-column .text-combination__copy--columns-2 {
        column-count: 2;
    }
}
