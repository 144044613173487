.service-badge {
    position: fixed;
    top: 100vh;
    right: 0;
    z-index: 101;

    display: flex;
    flex-direction: column;
    width: 100%;

    color: $color-white;

    transform: translateY(0);

    transition: $animation-speed ease transform;
}

.service-badge__trigger {
    position: absolute;
    top: -3px;
    right: 0;
    z-index: 2000;

    display: flex;
    align-self: flex-end;

    color: $color-white;
    font-size: 32px;

    background-color: $color-secondary-700;
    transform: translateY(-48px);
    cursor: pointer;

    transition: all $animation-speed;

    .service-badge__link {
        border-color: $color-primary;
    }
}

.service-badge__close {
    position: absolute;
    right: 14px;

    color: $color-secondary-100;
    font-size: 34px;

    transform: rotate(45deg);
    cursor: pointer;
}

.service-badge.service-badge--open {
    transform: translateY(-100%);

    .service-badge__trigger {
        color: $color-black;

        background: $color-secondary-700;
        box-shadow: 0 -3px 4px -2px fade_out($color-black, 0.5);

        .service-badge__link {
            border-color: $color-black;
        }
    }

    .service-badge__content {
        box-shadow: 0 -3px 4px -2px fade_out($color-black, 0.5);
    }
}

.service-badge__link {
    width: 60px;
    padding: 7px 10px;

    border-right: 1px solid $color-white;

    color: inherit;

    text-align: center;
    text-decoration: none;

    &:hover {
        color: inherit;
        text-decoration: none;
    }

    &:last-child {
        width: 58px;

        border-right: 0;
    }

    i {
        display: block;

        color: $color-black;
        font-size: 37px;
    }
}

.service-badge__content {
    padding: 20px 30px 30px;

    color: $color-secondary-100;

    background: $color-secondary-700;
}

.service-badge__headline {
    color: $color-secondary-100;
    font-weight: $font-weight-medium;
    font-size: 24px;
}

.service-badge__copy,
.service-badge__phone,
.service-badge__mail,
.service-badge__opening {
    display: block;

    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
}

.service-badge__copy {
    margin-top: 25px;
}

.service-badge__mail {
    margin-top: 40px;
}

.service-badge__phone {
    display: block;
    margin-top: 15px;
}

.service-badge__actions {
    .btn__wrapper {
        display: block;
        width: 100%;

        text-align: center;

        .btn {
            width: 100%;
            border-color: $color-secondary-100;

            color: $color-secondary-100;
        }
    }
}

@include media-breakpoint-up(md) {
    .service-badge {
        top: 80px;
        right: 0;

        flex-direction: row;
        width: auto;

        transform: translateX(100%) translateY(0);
    }

    .service-badge__trigger {
        top: 0;
        left: -70px;

        flex-direction: column;
        align-self: flex-start;
        width: 70px;

        box-shadow: -5px 2px 5px -1px fade_out($color-black, 0.5);
        transform: translateX(0) translateY(0);
    }

    .service-badge.service-badge--open {
        transform: translateX(0);

        .service-badge__trigger {
            box-shadow: -5px 2px 5px -1px fade_out($color-black, 0.5);
        }

        .service-badge__content {
            box-shadow: -5px 2px 5px -1px fade_out($color-black, 0.5);
        }
    }


    .service-badge__link {
        width: 70px;
        padding: 10px;
        border-right: 0;
        border-bottom: 2px solid $color-white;

        &:last-child {
            width: 70px;
            border-bottom: 0;
        }

        i {
            font-size: 53px;
            line-height: 52px;
        }
    }

    .service-badge__content {
        width: 530px;
        max-width: calc(100vw - 70px);
        padding: 30px 30px 30px 40px;
    }

    .service-badge__phone {
        display: none;
    }

    .service-badge__copy {
        margin-top: 15px;
    }

    .service-badge__actions {
        .btn__wrapper {
            width: 100%;

            text-align: left;

            .btn {
                width: auto;
            }
        }
    }

    .service-badge__mail {
        margin-top: 30px;
    }
}

@include media-breakpoint-up(xl) {
    .service-badge {
        top: 60px;
    }
}
