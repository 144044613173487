.header {
    position: fixed;
    top: 0;
    z-index: 100;

    width: 100%;
    max-width: 1920px;
    padding-top: 22px;
    padding-bottom: 22px;

    color: $color-white;

    background-color: $color-primary-600;
    transform: translateY(0%);

    transition: transform $animation-speed;
}

.header__content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.header__logo {
    display: flex;
    align-items: center;
    width: 200px;

    img {
        width: 100%;
    }
}

.header__desktop-navigation {
    display: none;
}

.header__desktop-controls {
    display: none;
}

.header__mobile-navigation {
    .icon-navigation__item {
        cursor: pointer;
    }
}

.site-wrapper.site-wrapper--header-fade-out {
    .header {
        transform: translateY(-100%);
    }
}

@include media-breakpoint-up(xl) {
    .header__navigation {
        width: 200%;

        .navigation--meta {
            min-height: 44px;
        }
    }

    .header__logo {
        min-width: 200px;
        margin-left: auto;
        padding-left: 20px;
    }

    .header {
        max-width: 100%;
        height: 60px;
        padding: 0;

        .container {
            height: 100%;
        }
    }

    .header__content {
        flex-direction: row;
        align-items: center;
        height: 100%;
        padding-right: 30px;
        padding-left: 30px;

        a {
            color: $color-white;
            text-decoration: none;
        }
    }

    .header__mobile-navigation {
        display: none;
    }

    .header__desktop-navigation {
        display: block;
    }

    .header__desktop-controls {
        display: flex;
        margin-right: 35px;

        .icon-navigation {
            margin: 0;
            padding-left: 15px;

            font-size: 28px;
        }

        .dropdown {
            font-size: 20px;
        }

        .dropdown-menu {
            top: 10px !important;
            z-index: 10;

            transform: translateY(35px) !important;

            &.show::before {
                top: -1.25rem;
                bottom: auto;

                border-top-color: transparent;
                border-bottom-color: fade_out($color-black, 0.3);
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .header__logo {
        min-width: 223px;
    }
}
