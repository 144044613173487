.childlisting__container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.childlisting__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: content-box;
    width: 100%;
    height: 80px;

    border-bottom: 2px solid #fff;

    background-color: $color-septenary-100;
}

.childlisting__img {
    width: 80px;
    height: 80px;
}

.childlisting__inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 10px 0 10px 20px;
}

.childlisting__subline {
    display: none;

    color: #fff;
}

.childlisting__headline {
    margin-bottom: 0;

    overflow: hidden;

    color: #fff;

    font-weight: 300;
    font-size: 18px;
    font-family: $font-primary-stack;
    line-height: 28px;
    text-transform: uppercase;
}

.childlisting__copy {
    display: none;
    height: 0;

    opacity: 0;
}

.childlisting--icon {
    z-index: 2;

    margin-right: 20px;
    margin-left: 20px;

    color: #fff;
    font-size: 24px;

    text-decoration: none;
    text-shadow: 1px 1px 5px #777;

    cursor: pointer;

    &:hover {
        color: #fff;

        text-decoration: none;
    }
}

@include media-breakpoint-up(md) {
    .childlisting__container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(318px, 1fr));

        column-gap: 1%;
    }

    .childlisting__wrapper {
        position: relative;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        height: calc(32vw - 0.125rem);
        margin-bottom: 2%;

        &:hover,
        &:active {
            .childlisting__inner-wrapper {
                max-width: 100%;
                height: 100%;
                margin: 0;
                padding: 35px 30px 10px 35px;
            }

            .childlisting__copy {
                display: block;
                height: auto;
                margin: 0 0 30px;

                opacity: 1;
            }
        }
    }

    .childlisting__img {
        position: absolute;
        z-index: 0;

        width: 100%;
        height: 100%;
    }

    .childlisting__inner-wrapper {
        z-index: 1;

        display: block;
        width: 100%;
        max-width: 85%;
        height: calc(35% + 15px);
        min-height: auto;
        margin: 20px 0 0 20px;
        padding: 15px 10px 10px 15px;

        background-color: fade_out($color-secondary-100, 0.2);

        transition: all ease-in-out $animation-speed;
    }

    .childlisting__subline {
        display: block;

        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;
    }

    .childlisting__headline {
        font-weight: 300;
        font-size: 18px;
    }

    .childlisting__copy {
        display: block;
        height: 0;
        margin: 0;

        overflow: hidden;

        transition: all ease-in-out $animation-speed;
    }

    .childlisting--icon {
        position: absolute;
        right: 0;
        bottom: 0;

        margin-bottom: 15px;

        color: #fff;
        font-size: 32px;
    }
}

@include media-breakpoint-up(xl) {
    .childlisting__wrapper {
        width: 100%;
        height: 18vw;
        margin-bottom: 4%;
    }

    .childlisting__inner-wrapper:hover {
        max-width: 100%;
        height: 100%;
        margin: 0;
        padding: 35px 35px 10px;

        .childlisting__copy {
            display: block;
            height: auto;

            opacity: 1;
        }
    }

    .childlisting__inner-wrapper {
        max-width: 80%;
        height: calc(36% + 15px);
    }

    .childlisting__subline {
        font-size: 12px;
    }

    .childlisting__headline {
        display: flex;

        font-size: 16px;
        line-height: 22px;
    }

    .childlisting__copy {
        height: 0;
        margin-top: 5px;

        font-size: 14px;
        line-height: 1.5;
    }

    .childlisting--icon {
        font-size: 32px;
    }
}
