.submenu {
    padding: 20px;

    overflow-x: auto;

    background-color: $color-septenary-100;

    &--background-70 {
        background-color: fade_out($color-septenary-100, 0.1);
    }

    &--background-60 {
        background-color: fade_out($color-septenary-100, 0.2);
    }
}

.submenu__navigation-list {
    padding: 0;

    list-style: none;
}

.submenu__navigation-link {
    display: flex;
    align-items: center;
    padding: 10px;

    color: $color-white;
    text-transform: uppercase;

    opacity: 0.7;

    transition: opacity $animation-speed;

    &:hover,
    .submenu__navigation-link--active {
        color: $color-white;

        opacity: 1;
    }
}

.submenu__navigation-item {
    a {
        text-decoration: none;
    }
}
