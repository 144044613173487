.content-main,
.content-head {
    margin-top: 80px;
}

.content-head + .content-main {
    margin-top: 0;
}

@include media-breakpoint-up(xl) {
    .content-head,
    .content-main {
        margin-top: 60px;
    }

    .content-head + .content-main {
        margin-top: 0;
    }
}
